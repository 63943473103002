import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { Button } from '@mui/material';
import { useLazyGetCargosListQuery } from '../../../../store/newApi/cargos/cargos';
 
import { formatInitialDate } from '../../../../helpers/formatDate';
import { useDispatch } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/cargos.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { FILTER_TYPES } from '../../../../config/filter';
import { CargoForm } from '../../../../newComponents/Form/CargoForm/CargoForm.jsx';
import Cookies from 'js-cookie';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplTableCargos() {
	const userType = Cookies.get('userType');
	const dispatch = useDispatch();
	const [fetchCargo, { data, isFetching, isLoading, isError }] = useLazyGetCargosListQuery();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isFormOpened, setIsFormOpened] = useState(false);
	

	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	// колонки
	const columnsList = useMemo(
		() => {
			const baseColumns = [ 
			{
				accessorFn: (row) => row.terminal_display,
				id: 'terminal_display',
				header: 'Терминал',
				size: 200,
			},
			{
				accessorFn: (row) => row.package,
				id: 'cell',
				header: 'Ячейка',
				size: 200,
			},
			{
				accessorFn: (row) => row.gng_code,
				id: 'gng_code',
				header: 'Код ГНГ',
				size: 180,
			},
			{
				accessorFn: (row) => row.cargo,
				id: 'cargo',
				header: 'Груз',
				size: 300,
			},
			{
				accessorFn: (row) => row.package,
				id: 'package',
				header: 'Тара',
				size: 150,
			},
			{
				accessorFn: (row) => row.package_count,
				id: 'package_count',
				header: 'Кол-во тары',
				size: 200,
			},
			{
				accessorFn: (row) => (row.is_pallet ? 'Да' : '-'),
				id: 'is_pallet',
				header: 'Паллеты',
				size: 190,
			},
			{
				accessorFn: (row) => row.pallet_count,
				id: 'pallet_count',
				header: 'Кол-во паллетов',
				size: 220,
			},
			{
				accessorFn: (row) => row.gross,
				id: 'gross',
				header: 'Вес брутто',
				size: 180,
			},
			{
				accessorFn: (row) => (row.checkin_at ? formatInitialDate(row.checkin_at) : '-'),
				id: 'checkin_at',
				header: 'Дата поступления',
				size: 240,
			},
			{
				accessorFn: (row) => (row.eta_checkout ? formatInitialDate(row.eta_checkout).split(' ')[0] : '-'),
				id: 'eta_checkout',
				header: 'Планируемая дата вывоза',
				size: 290,
			},
		];
	
			
		if (userType == 1) {
			baseColumns.push({
				accessorFn: (row) => row.client_display,
				id: 'client_display',
				header: 'Клиент',
				size: 300,
			});
		}

		return baseColumns;
	},
	[userType], 
);

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsFormOpened(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsFormOpened(true);
	}, []);

	const handleClose = useCallback(() => {
		setRowSelection({});
		setIsFormOpened(false)
	}, [setRowSelection]);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'client_display',
		'terminal_display',
		'cell',
		'gng_code',
		'cargo',
		'package',
		'package_count',
		'is_pallet',
		'pallet_count',
		'gross',
		'checkin_at',
		'eta_checkout',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Грузы'}
				btnDesktop={[
					selectedRows.length > 0 && <Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
						Изменить
					</Button>,
					<Button variant="contained" color="primary" onClick={handleCreate}>Добавить груз</Button>,
				]}
				btnMobile={[
					selectedRows.length > 0 && <Button variant="contained" color="secondary" size="small" onClick={handleUpdate}>
						Изменить
					</Button>,
					<Button variant="contained" color="primary" onClick={handleCreate} size='small'>Добавить груз</Button>,
				]}

				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['pallet_count_gte', 'pallet_count_lte'],
								title: 'Кол-во паллетов',
								text: 'кол-во',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['paсkage_count_gte', 'package_count_lte'],
								title: 'Кол-во тары',
								text: 'кол-во',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['checkin_at_from', 'checkin_at_to'],
								title: 'Дата поступления',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['eta_checkout_from', 'eta_checkout_at_to'],
								title: 'Дата вывоза',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['gross_gte', 'gross_lte'],
								title: 'Вес брутто',
								text: 'вес',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}> 
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchCargo}
						isLoading={isLoading}
						isFetching={isFetching}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="cargosRtk"
					/>
					<CargoForm
						isOpen={isFormOpened}
						handleClose={handleClose}
						entityIds={selectedRows}
						refetchData={() => fetchCargo({ page: 1, page_size: 30 })}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableCargos;
