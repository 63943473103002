import React, { useEffect, useMemo } from 'react';
import styles from '../AccountEmpl.module.scss';
import { useParams } from 'react-router-dom';
import Header from '../../../../newComponents/Header/Header';
import { useLazyGetEntryQuery, useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { Box, Button } from '@mui/material';
import { Preloader } from '../../../../components';
import { account } from './Account.styles.js';
import { EntriesForm } from '../../../../newComponents/Form/EntriesForm/EntriesForm.jsx'; 
import { isEmpl } from '../../../../shared/auth/isEmpl'; 
import { usePrintDocMutation } from '../../../../store/newApi/print/print';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

function AccountEmplEntryPage() {
	const { showNotificaton } = useNotify();
	let { terminalId, orderId, entryId } = useParams();
	const [trigger, { data, isLoading }] = useLazyGetEntryQuery({});
	const [updateEntry] = useUpdateEntryMutation();
	const [printDoc, { isLoading: isLoadingPrint }] = usePrintDocMutation();

	useEffect(() => {
		if (terminalId && orderId && entryId) {
			trigger({ terminalId, orderId, entryId });
		}
	}, [terminalId, orderId, entryId]);

	const handleChangeStatus = () => {
		updateEntry({ terminalId, orderId, pass_pk: entryId, status: 1 }).then((data) => {
			if (data.error) {
				showNotificaton({
					type: 'error',
					message: tranformErrorsToRender(data.error?.data, 'Не удалось использовать пропуск'),
				});
			} else {
				trigger({ terminalId, orderId, entryId });
				showNotificaton({
					type: 'success',
					message: 'Пропуск успешно использован',
				});
			}
		});
	};

	const getHeaderBtns = useMemo(() => {
		let btns = [
			<Button
				variant="contained"
				color="secondary"
				onClick={() =>
					printDoc({
						template_id: 3,
						instance_id: data?.id,
					})
				}
			>
				Распечатать
			</Button>,
		];
		if ((isEmpl() && data?.status == 0)) {
			btns.push(
				<Button variant="contained" color="primary" onClick={handleChangeStatus}>
					Использовать пропуск
				</Button>,
			);
		} else {
			btns.push(data?.status_display);
		}
		return btns;
	}, [data]);

	return (
		<>
			{isLoadingPrint && <div style={{
                position: 'absolute',
                zIndex: 10000,
                width: '100vw',
                height: '100vh',
                backgroundColor: '#eeea',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* @ts-ignore */}
                <Preloader />
            </div>}
			<div className={styles.account__wrap}>
			<Header
				text={isLoading ? '' : !terminalId && !orderId && !entryId ? 'Создать пропуск' : `${data ? data?.name : ''}`}
				btnDesktop={getHeaderBtns}
				btnMobile={getHeaderBtns}
			/>
			{isLoading ? (
				<Box sx={account.preloader}>
					<Preloader />
				</Box>
			) : (
				<div className={styles.account__container}>
					<div className={styles.account__stack}>
						<EntriesForm
							refetchData={() => trigger({ terminalId, orderId, entryId })}
							isCreate={!terminalId && !orderId && !entryId}
							entry={data}
						/>
					</div>
				</div>
			)}
		</div>
		</>
	);
}

export default AccountEmplEntryPage;
