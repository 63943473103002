import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import styles from './ChooseServicesStep.module.css'
import { useCreateRepairTaskMutation, useDeleteRepairTaskMutation, useLazyGetRepairGroupByIdQuery, useUpdateRepairGroupMutation, useUpdateRepairTaskMutation } from '../../../../../../../store/newApi/service/service';
import { Typography } from '@mui/material';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import RViewerJS from 'viewerjs-react';
import { useGetPermissions } from '../../../../../../../shared/auth/hooks/useGetPermissions';
import { getNextStatus, isOnRepair } from '../../../../../../../shared/repairs/constants';
import { PageTabs } from '../../../../../../../shared/ui';
import { ChooseRepairTasks } from './ChooseRepairTasks';
import { RepairPhotoFix } from './RepairPhotoFix';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';

export const ChooseServicesStep = memo(({
    refetchRepairOrder,
    containerInfo,
    repairGroupId,
    onBack,
    changedRepairGroupStatus,
    handleChangeRepairGroupStatus,
    handleSetPhotoFix
}) => {
    const [repairGroupInfo, setRepairGroupInfo] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [price, setPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [currentRepairStep, setCurrentReparStep] = useState(0);
    const { isRemontnik, isClient } = useGetPermissions();

    const disableEdit = useMemo(() => isClient() || isRemontnik, [isRemontnik])

    const [createRepairTask, { data: changedRepairGroupData, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateRepairTaskMutation();
    const [deleteRepairTask, { data: deleteRepairGroupData, isSuccess: isDeleteSuccess, reset: resetDelete }] = useDeleteRepairTaskMutation();
    const [fetchRepairGroup, { data: repairGroupData }] = useLazyGetRepairGroupByIdQuery();
    const [updateRepairGroup, { data: updatedRepairGroup, isSuccess: isUpdateRepairGroupSucess, reset: resetUpdateRepairGroup }] = useUpdateRepairGroupMutation();
    const [updateRepairTask, { data: updatedRepairTask }] = useUpdateRepairTaskMutation();

    const repairTasks = useMemo(() => repairGroupInfo?.repair_tasks || [], [repairGroupInfo]);
    const totalPrice = useMemo(() => repairGroupInfo?.total_price || null, [repairGroupInfo]);
    const totalMaterialPrice = useMemo(() => repairGroupInfo?.total_material_price || null, [repairGroupInfo]);
    const totalLaborPrice = useMemo(() => repairGroupInfo?.total_labor_price || null, [repairGroupInfo]);
    const discountValue = useMemo(() => repairGroupInfo?.discount_value || 0, [repairGroupInfo]);
    const damageImages = useMemo(() => repairGroupInfo?.damage?.pictures[0] || [], [repairGroupInfo]);

    useEffect(() => {
        if (isCreateSuccess) {
            setShowAddForm(false)
            resetCreate()
        }
    }, [isCreateSuccess, resetCreate]);

    useEffect(() => {
        fetchRepairGroup({ id: repairGroupId });
    }, [fetchRepairGroup, repairGroupId])

    useEffect(() => {
        if (isDeleteSuccess)
            fetchRepairGroup({ id: repairGroupId });
    }, [isDeleteSuccess, repairGroupId, fetchRepairGroup])

    useEffect(() => {
        if (isUpdateRepairGroupSucess) {
            refetchRepairOrder();
            resetUpdateRepairGroup()
            onBack()
        }
    }, [isUpdateRepairGroupSucess, resetUpdateRepairGroup, refetchRepairOrder, onBack]);

    useEffect(() => {
        if (repairGroupData) {
            setRepairGroupInfo(repairGroupData)
            handleChangeRepairGroupStatus(repairGroupData.status)
        }
    }, [repairGroupData, handleChangeRepairGroupStatus])

    useEffect(() => {
        if (changedRepairGroupData) {
            setRepairGroupInfo(changedRepairGroupData)
        }
    }, [changedRepairGroupData]);

    useEffect(() => {
        if (updatedRepairGroup) {
            setRepairGroupInfo(updatedRepairGroup)
        }
    }, [updatedRepairGroup]);

    const handleChangePrice = useCallback((e) => {
        setPrice(e.target.value);
    }, []);

    const handleChangePercent = useCallback((e) => {
        setDiscount(e.target.value);
    }, []);

    const handleSave = useCallback(() => {
        updateRepairGroup({ id: repairGroupInfo.id, status: getNextStatus(isRemontnik, changedRepairGroupStatus) })
    }, [updateRepairGroup, repairGroupInfo, changedRepairGroupStatus, isRemontnik]);

    const handleChangeStep = useCallback((taskId, steps) => {
        updateRepairTask({ id: taskId, steps })
    }, [updateRepairTask]);

    const handleDeleteTask = useCallback((id) => {
        deleteRepairTask({ repair_task_pk: id })
    }, [deleteRepairTask])

    const handleAddRepairTask = useCallback((repairTaskId) => {
        if (repairGroupInfo)
            createRepairTask({
                repair_group_pk: repairGroupInfo.id,
                repair_task: repairTaskId
            })
    }, [repairGroupInfo, createRepairTask]);

    const handleUpdatePrice = useCallback((e) => {
        updateRepairGroup({ id: repairGroupInfo.id, total_price: +e.target.value })
    }, [updateRepairGroup, repairGroupInfo])

    const handleUpdatePercent = useCallback((e) => {
        updateRepairGroup({ id: repairGroupInfo.id, discount_value: +e.target.value })
    }, [updateRepairGroup, repairGroupInfo])

    useEffect(() => {
        setPrice(totalPrice);
        setDiscount(discountValue);
    }, [totalPrice, discountValue]);

    if (!repairGroupInfo) return null;

    return (
        <div className={styles.container} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between', gap: '10px' }}>
            <div className={styles.repairServiceContainer}>
                <div className={styles.repairInfo} style={{ padding: '10px' }}>
                    <Typography variant="h6">
                        Описание повреждения
                    </Typography>
                    <div style={{ display: 'flex', gap: '12px', flexDirection: 'column', marginTop: '12px' }}>
                        <InputText labelText='Сторона' value={repairGroupInfo.damage?.side} disabled />
                        <InputText labelText='Тип' value={repairGroupInfo.damage?.damage[0]?.name} disabled />
                        <InputText labelText='Область' value={repairGroupInfo.damage?.component[0]} disabled />
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <InputText labelText='Длинна, мм' value={repairGroupInfo.damage?.dimensions?.l} disabled />
                            <InputText labelText='Ширина, мм' value={repairGroupInfo.damage?.dimensions?.w} disabled />
                            <InputText labelText='Глубина, мм' value={repairGroupInfo.damage?.dimensions?.d} disabled />
                        </div>
                    </div>
                    <RViewerJS className={styles.viewer}>
                        {damageImages?.map((image, index) => (
                            <div key={`image_wrapper_${index}`} className={styles.onePhotoCard}>
                                <img src={image.url} alt="example" />
                            </div>
                        ))}
                    </RViewerJS>
                </div>
                <div className={styles.repairInfo} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <PageTabs
                            tabs={[{ title: 'Выбор услуг', step: 0 }, { title: 'Фотофиксация', step: 1 }]}
                            currentStep={currentRepairStep}
                            onTabClick={setCurrentReparStep}
                        />
                    </div>
                    <div style={{ padding: '10px' }}>
                        {currentRepairStep === 0 && <ChooseRepairTasks
                            repairTasks={repairTasks}
                            disableEdit={disableEdit}
                            handleChangeStep={handleChangeStep}
                            showAddForm={showAddForm}
                            setShowAddForm={setShowAddForm}
                            totalPrice={totalPrice}
                            totalMaterialPrice={totalMaterialPrice}
                            totalLaborPrice={totalLaborPrice}
                            containerInfo={containerInfo}
                            handleAddRepairTask={handleAddRepairTask}
                            handleChangePrice={handleChangePrice}
                            price={price}
                            handleChangePercent={handleChangePercent}
                            discount={discount}
                            discountValue={discountValue}
                            handleUpdatePercent={handleUpdatePercent}
                            handleUpdatePrice={handleUpdatePrice}
                            handleDeleteTask={handleDeleteTask}
                        />}
                        {currentRepairStep === 1 && <RepairPhotoFix
                            repairGroupId={repairGroupId}
                            disabled={!isRemontnik || !isOnRepair(repairGroupData?.status)}
                            handleSetPhotoFix={handleSetPhotoFix}
                        />}
                    </div>
                </div>
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Назад',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    },
                ]}
                rightBtns={[
                    {
                        text: 'Сохранить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: handleSave
                    }
                ]}
            />
        </div>
    )
})