import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from '../../../../../newComponents/Header/Header';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PageTabs } from '../../../../../shared/ui';
import styles from './CreateInspectionPage.module.css';
import { InspectionInfoStep } from './components/InspectionInfoStep/InspectionInfoStep';
import { useLazyGetEntryReportByIdQuery, useUpdateEntryReportMutation } from '../../../../../store/newApi/entryreports/entryreports';
import { InspectionDamagesStep } from './components/InspectionDamagesStep/InspectionDamagesStep';
import { Button, useMediaQuery } from '@mui/material';
import { InspectionCreateDamageStep } from './components/InspectionCreateDamageStep/InspectionCreateDamageStep';
import { ENTRYREPORT_STATUSES, ENTRYREPORT_TYPE } from '../../../../../shared/entryreports/constants';
import { INSPECTION_LIST_URL } from '../../../../../shared/inspection/pageUrls';
import { InspectionPhotoStep } from './components/InspectionPhotoStep/InspectionPhotoStep';
import AddIcon from '@mui/icons-material/Add';
import { DamagePictureApi } from '../../../../../shared/entryreports/types';
import { useConfirmationModal } from '../../../../../shared/providers/PopupProvider/ModalProvider';
import { DocTemplates, usePrintDocMutation } from '../../../../../store/newApi/print/print';
import { isEmpl } from '../../../../../shared/auth/isEmpl';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { Preloader } from 'components';

export const CreateInspectionPage = () => {
	const { inspectionId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { showNotificaton } = useNotify();
	// @ts-ignore
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	const [params] = useSearchParams();
	const step = params.get('step');
	const orderType = params.get('orderType');
	const damageId = params.get('damageId');

	const [currentStep, setCurrentStep] = useState(0);
	const [selectedPictures, setSelectedPictures] = useState<DamagePictureApi[]>([]);

	const { openModal } = useConfirmationModal();

	const [fetchEntryReport, { data: entryReportData, isFetching: isFetchingEntryReport }] = useLazyGetEntryReportByIdQuery();
	const [updateEntryReport] = useUpdateEntryReportMutation();
	const [printDoc, { data, isLoading: isLoadingPrint }] = usePrintDocMutation();

	const handleFetchEntryReport = useCallback(() => {
		if (inspectionId) fetchEntryReport({ id: inspectionId });
	}, [inspectionId, fetchEntryReport]);

	useEffect(() => {
		handleFetchEntryReport();
	}, [handleFetchEntryReport]);

	useEffect(() => {
		if (step) setCurrentStep(+step);
	}, [step]);

	const isEntryReportOutType = useMemo(() => entryReportData && entryReportData.type === ENTRYREPORT_TYPE.OUT.id, [entryReportData]);

	const isAccepted = useMemo(() => entryReportData?.status === ENTRYREPORT_STATUSES.ACCESSED.id, [entryReportData]);

	const disableEdit = useMemo(() => isAccepted, [entryReportData]);

	const headerTitle = useMemo(() => {
		if (disableEdit) return 'Редактирование акта осмотра';
		return 'Создание акта осмотра';
	}, [disableEdit]);

	const handleChangeStep = useCallback(
		(step) => {
			navigate(`${location.pathname}?step=${step}`);
		},
		[navigate, location],
	);

	const isCreating = useMemo(() => {
		return !entryReportData;
	}, [entryReportData]);

	const disableDamages = useMemo(() => {
		return +orderType === ENTRYREPORT_TYPE.OUT.id || entryReportData?.type === ENTRYREPORT_TYPE.OUT.id;
	}, [orderType, entryReportData]);

	useEffect(() => {
		if (entryReportData) {
			setSelectedPictures((prevState) =>
				[...prevState, ...(entryReportData?.report_photos || [])].filter(
					(obj, index, self) => index === self.findIndex((t) => t.filename === obj.filename),
				),
			);
		}
	}, [entryReportData]);

	const handleSetPicture = useCallback(
		(images) => {
			setSelectedPictures(images);
		},
		[selectedPictures],
	);

	const handleFinish = useCallback(
		(status: number, successMessage = 'Акт успешно сохранен') => {
			if (entryReportData)
				updateEntryReport({ id: entryReportData?.id, report_photos: selectedPictures, status }).then((data) => {
					// @ts-ignore
					if (!!data?.error) {
						showNotificaton({ type: 'error', message: 'Не удалось сохранить акт' });
					} else {
						handleChangeStep(0);
						handleFetchEntryReport();
						showNotificaton({ type: 'success', message: successMessage });
					}
				});
		},
		[entryReportData, selectedPictures, handleFetchEntryReport],
	);

	const renderStep = useCallback(() => {
		switch (currentStep) {
			case 0:
				return (
					<InspectionInfoStep
						refetchEntryReport={fetchEntryReport}
						entryReportDefaultData={entryReportData}
						containerId={entryReportData?.container?.id || null}
						entryReportId={entryReportData?.id}
						onBack={() => navigate(INSPECTION_LIST_URL)}
						orderType={orderType}
						disableDamages={disableDamages}
					/>
				);
			case 1:
				return (
					<InspectionDamagesStep
						damages={entryReportData?.damages || []}
						entryReportId={entryReportData?.id}
						isFetching={isFetchingEntryReport}
						refetchEntryReport={fetchEntryReport}
						disableEdit={disableEdit}
						onBack={() => handleChangeStep(1)}
						onNext={() => handleChangeStep(3)}
					/>
				);
			case 2:
				return (
					<InspectionCreateDamageStep
						entryReportData={entryReportData}
						isFetching={isFetchingEntryReport}
						selectedDamageId={damageId}
						onBack={() => handleChangeStep(1)}
						disableEdit={disableEdit}
					/>
				);
			case 3:
				return (
					<InspectionPhotoStep
						entryReportData={entryReportData}
						isFetching={isFetchingEntryReport}
						onBack={() => handleChangeStep(1)}
						disableEdit={disableEdit}
						onAddPictures={handleSetPicture}
						selectedPictures={selectedPictures}
						handleFinish={handleFinish}
					/>
				);
		}
	}, [
		currentStep,
		entryReportData,
		handleChangeStep,
		orderType,
		isFetchingEntryReport,
		selectedPictures,
		damageId,
		disableDamages,
		disableEdit,
		handleFinish,
	]);

	const handleAddDamage = useCallback(() => {
		handleChangeStep(2);
	}, [handleChangeStep]);

	const headerBtns = useMemo(() => {
		const btns = [];
		if (currentStep === 1 && entryReportData?.type === ENTRYREPORT_TYPE.IN.id && !disableEdit && isEmpl()) {
			btns.push(
				<Button variant="contained" color="primary" size={isDesktop ? 'medium' : 'small'} onClick={handleAddDamage}>
					{isDesktop && 'Добавить повреждение'}
					{!isDesktop && (
						<>
							Повреждение
							<AddIcon />
						</>
					)}
				</Button>,
			);
		}
		if ([0, 3].includes(currentStep) && !isCreating && !disableEdit && isEmpl()) {
			btns.push(
				<Button
					variant="contained"
					color="secondary"
					size={isDesktop ? 'medium' : 'small'}
					onClick={() =>
						openModal('Акт уже нельзя будет изменить. Сформировать акт?', () =>
							handleFinish(ENTRYREPORT_STATUSES.ACCESSED.id, 'Акт успешно сформирован'),
						)
					}
				>
					Сформировать акт
				</Button>,
			);
		}
		if (isAccepted) {
			btns.push(
				<Button
					disabled={!entryReportData}
					variant="contained"
					color="secondary"
					size={isDesktop ? 'medium' : 'small'}
					onClick={() =>
						printDoc({
							template_id: isEntryReportOutType ? DocTemplates.ACT_OUT : DocTemplates.ACT_ACCEPTANCE,
							instance_id: entryReportData?.id,
						})
					}
				>
					Распечатать
				</Button>,
			);
		}
		return btns;
	}, [currentStep, entryReportData, handleAddDamage, disableEdit, isCreating, selectedPictures, handleFinish, isAccepted]);

	const hideTabs = useMemo(() => currentStep === 2, [currentStep]);

	return (
		<>
            {isLoadingPrint && <div style={{
                position: 'absolute',
                zIndex: 10000,
                width: '100vw',
                height: '100vh',
                backgroundColor: '#eeea',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* @ts-ignore */}
                <Preloader />
            </div>}
			<div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
				{/* @ts-ignore */}
				<Header text={headerTitle} btnDesktop={headerBtns} btnMobile={headerBtns} />
				<div className={styles.content}>
					<PageTabs
						tabs={[
							{ title: 'Информация', step: 0 },
							{ title: 'Повреждения', step: 1, disabled: disableDamages || isCreating },
							{ title: 'Фотофиксация', step: 3, disabled: isCreating },
						]}
						onTabClick={handleChangeStep}
						currentStep={currentStep}
						hidden={hideTabs}
					/>
					<div className={styles.steps}>{renderStep()}</div>
				</div>
			</div>
		</>
	);
};
